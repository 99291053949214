import React, { Component } from 'react';
import Board from './board';
import Infobar from './Infobar';
import './App.css';
import './Boards/Potato.css';
import './Pieces/Marble.css';
import { o, x, _ } from './const';

var selected = {
    x: null,
    y: null,
};
var chain = [];

function ASCII(c) {
    return c.charCodeAt(0);
}
function fromNotation(n) {
    return {
        x: ASCII(n) - ASCII('A'),
        y: n[1] - 1,
    };
}
function toArray(n) {
    if (n === null) return [];
    return n.split(n.slice(2, 3));
}

export default class App extends Component {
    state = {
        dragging: null,
        humanTurn: true,
        validMoves: [
            'A3-B4',
            'C3-B4',
            'C3-D4',
            'E3-D4',
            'E3-F4',
            'G3-F4',
            'G3-H4',
        ],
        lastMove: '',
        matrix: [
        //   0 1 2 3 4 5 6 8      index
        //   1 2 3 4 5 6 7 8    value
            [_,_,_,_,_,_,_,_], // A 0
            [_,_,_,_,_,_,_,_], // B 1
            [_,_,_,_,_,_,_,_], // C 2
            [_,_,_,_,_,_,_,_], // D 3
            [_,_,_,_,_,_,_,_], // E 4
            [_,_,_,_,_,_,_,_], // F 5
            [_,_,_,_,_,_,_,_], // G 6
            [_,_,_,_,_,_,_,_], // H 7
        ],
    };
    take = (e, id) => {
        e.preventDefault();
        if (!this.state.humanTurn) return;
        selected = id;
        chain.push(id);
        this.setState({
            dragging: id,
            lastMove: chain,
        });

        // for (var i = 0, arr = this.state.validMoves, len = arr.length; i < len; i++) {
        //   if (arr[i].indexOf(id) !== 0) continue;
        //   from = id;
        //   this.setState({
        //     dragging: true,pieces
        //   });
        //   return;
        // }
    };
    place = (e, id) => {
        e.preventDefault();
        if (!this.state.dragging || !this.state.humanTurn) return;
        const move = this.move(selected, id);
        if (move === 'move') {
            chain.push(id);
            return;
        }
        if (move === 'end') {
            chain.pop();
            this.makeMove();
        }
        if (move === 'occupied' || move === 'off') {
            chain = [];
            this.makeMove(); // returns unsuccessful anyway sooo
        }
        selected = {
            x: null,
            y: null,
        };
        this.setState({
            dragging: null,
        });
    };
    move(from, to) {
        if (from === to) return 'end';

        const frVal = fromNotation(from);
        const toVal = fromNotation(to);
        var matrix = this.state.matrix;

        if (matrix[toVal.x][toVal.y] !== '_') return 'occupied';
        if ((toVal.x + toVal.y) % 2) return 'off';

        matrix[toVal.x][toVal.y] = matrix[frVal.x][frVal.y];
        matrix[frVal.x][frVal.y] = _;

        selected = to;
        this.setState({
            matrix: matrix,
            lastMove: chain,
        });
        return 'move';
    }

    newGame = () => {
        console.log('New game');
        fetch('/new-game')
            .then(res => {
                return res.json();
            })
            .then(body => {
                console.log(body);
                if (!body.success) return
                this.setState({
                    matrix: [
                    //   0 1 2 3 4 5 6 8      index
                    //   1 2 3 4 5 6 7 8    value
                        [o,_,o,_,_,_,x,_], // A 0
                        [_,o,_,_,_,x,_,x], // B 1
                        [o,_,o,_,_,_,x,_], // C 2
                        [_,o,_,_,_,x,_,x], // D 3
                        [o,_,o,_,_,_,x,_], // E 4
                        [_,o,_,_,_,x,_,x], // F 5
                        [o,_,o,_,_,_,x,_], // G 6
                        [_,o,_,_,_,x,_,x], // H 7
                    ],
                });
            });
    };

    receive(body) {
        console.log(body);
        chain = [];
        if (!body.success) return
        let data = body.data
        this.setState({
            humanTurn: data.whos_move === 'W',
            lastMove: toArray(data.last_move),
            matrix: data.board_matrix,
            isFinished: data.is_finished,
            isAnnounced: false
        });
        if (data.winner === 'W') {
            alert('You win!');
        }
        else if (data.winner === 'B') {
            alert('You lose!');
        }
    }
    makeMove() {
        this.setState({
            humanTurn: false,
        });
        const body = JSON.stringify({
            chain_notation: chain,
        });
        console.log('Stringified:', body);
        fetch('/make-move', {
            method: 'POST',
            body: body,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })
            .then(res => {
                // console.log(res);
                return res.json();
            })
            .then(data => this.receive(data));
        // setTimeout(() => {
        //   this.receive(chain);
        // }, 1000);
    }

    render() {
        return (
            <div id="App" className="App">
                <Infobar newGame={this.newGame} />
                <Board
                    matrix={this.state.matrix}
                    lastMove={this.state.lastMove}
                    take={this.take}
                    place={this.place}
                />
            </div>
        );
    }
}
