import React, { Component } from 'react'
import Tile from './tile'

function ASCII(c) {
    return c.charCodeAt(0)
}
function letter(n) {
    return String.fromCharCode(n + ASCII('A'))
}
function toNotation(x, y) {
    return letter(x) + (y + 1)
}

export default class Row extends Component {
    render() {
        const matrix = this.props.matrix
        const row = this.props.row
        const y = this.props.y
        const rankLabel = (
            <td className="rank tile label">
                <b>{y + 1}</b>
            </td>
        )
        return (
            <tr key={y}>
                {rankLabel}
                {row.map((tile, x) => (
                    <Tile
                        key={toNotation(x, y + 1)}
                        matrix={matrix}
                        x={x}
                        y={y}
                        take={this.props.take}
                        place={this.props.place}
                        lastMove={this.props.lastMove}
                    />
                ))}
                {rankLabel}
            </tr>
        )
    }
}
