import React, { Component } from 'react';
import Row from './row';

export default class Board extends Component {
    render() {
        const matrix = this.props.matrix;
        const fileLabels = (
            <tr>
                <td className="corner tile"></td>
                {'ABCDEFGH'.split('').map(val => (
                    <td key={val} className="file tile label">
                        <b>{val}</b>
                    </td>
                ))}
                <td className="corner tile"></td>
            </tr>
        );
        return (
            <table className="board">
                <tbody>
                    {fileLabels}
                    {matrix.map((row, y) => (
                        <Row
                            key={y}
                            matrix={matrix}
                            row={row}
                            y={7 - y}
                            take={this.props.take}
                            place={this.props.place}
                            lastMove={this.props.lastMove}
                        />
                    ))}
                    {fileLabels}
                </tbody>
            </table>
        );
    }
}
