import React, { Component } from 'react';
import Popup from './popup';

export default class Infobar extends Component {
    state = {
        contentId: -1,
    };
    show = id => {
        this.setState({
            contentId: this.state.contentId === id ? -1 : id,
        });
    };
    contents = [
        <>
            <h1>Menu</h1>
            <div>
                <div>
                    <p>New Game</p>
                    <Popup
                        click={this.props.newGame}
                        className="oi oi-media-play"
                    />
                </div>
            </div>
        </>,
        <>
            <h1>Introduction</h1>
            <div>
                <p>Hello and welcome to my Checkers web app!</p>
                <p>
                    This app was made by me, Raffi Molero, as a front-end: a way
                    for people to play the game online.
                </p>
                <p>
                    Right now, we're still developing the game. If you feel that
                    the game sucks, that's because it's not finished. Feel free
                    to make any suggestions on Messenger or Discord.
                </p>
                <h3>Click the question mark button to see how to play.</h3>
            </div>
        </>,
        <>
            <h1>How To Play</h1>
            <div>
                <h2>Starting a new game</h2>
                <p>
                    To start a new game, click the menu button (the button with
                    3 lines) and then the play button inside of it (the button
                    with a triangle) and you're set.
                </p>
                <p>
                    To move a piece, first select it by clicking it, then move
                    the piece by clicking on any empty tile.
                </p>
                <p>
                    Moving a piece doesn't deselect it, so you can keep moving
                    it until you end the turn.
                </p>
                <p>
                    To end a turn, click on the same piece again to do the move.
                </p>
                <p>
                    If you think you made a mistake, you can click on a random
                    white tile or piece anytime to cancel the move.
                </p>
            </div>
        </>,
        <>
            <h1>About This Project</h1>
            <div>
                <p>
                    This project was made from an idea my dad had in summer
                    2019.
                </p>
                <p>
                    He thought of making an AI that could play checkers, by
                    storing data about each game in a large database. We then
                    started working on it, starting by making the back-end using
                    Python.
                </p>
                <p>
                    Eventually, we made a working project that would recognize
                    checkers moves, all that was left was to make something
                    people could interact with, a front-end.
                </p>
                <p>
                    I was a bit tired, and school was starting, so I took a long
                    break. 4 months(!) later, after being reminded by my dad, I
                    decided to get back to work and make the front-end. Within
                    the span of 3 weeks, I learned HTML, CSS, Javascript, then
                    React.js, and programmed and designed this entire checkers
                    web app.
                </p>
                <p>
                    Everything, from the board, to the tiles, to the pieces, to
                    this text, was done in that time.
                </p>
                <h3>
                    Up until now, We're still developing both the AI and the
                    front-end.
                </h3>
            </div>
        </>,
        <>
            <h1>Credits</h1>
            <div>
                <p>
                    Big thanks to{' '}
                    <a href="https://stackabuse.com/the-node-js-request-module/">
                        This page
                    </a>
                    , Specifically in the section "FORMS" for finally ending our
                    days-worth of efforts trying to find the correct way to send
                    a post request.
                </p>
                <p>
                    They ended the headaches of "ERROR", "statusCode: 400", and
                    "Bad Request" with it.
                </p>
            </div>
        </>,
    ];
    icons = ['menu', 'info', 'question-mark', 'document', 'book'];
    render() {
        const popups = (
            <div className="side infobar">
                {this.contents.map((c, i) => {
                    return (
                        <Popup
                            click={() => this.show(i)}
                            key={i}
                            className={'oi oi-' + this.icons[i]}
                        />
                    );
                })}
            </div>
        );
        if (this.state.contentId !== -1) {
            return (
                <>
                    <div className="side infobar">{popups}</div>
                    <div className="side textbox">
                        {this.contents[this.state.contentId]}
                    </div>
                </>
            );
        }
        return (
            <>
                <div className="side infobar">{popups}</div>
            </>
        );
    }
}
