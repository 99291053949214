export const o = 'o'
export const x = 'x'
export const O = 'O'
export const X = 'X'
export const _ = '_'

export default function getInfo(p) {
    return {
        isWhite: 'oO'.includes(p),
        isBlack: 'xX'.includes(p),
        isMan: 'ox'.includes(p),
        isKing: 'OX'.includes(p),
        isEmpty: '_' === p,
    }
}
