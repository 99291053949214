import React, { Component } from 'react'

export default class Popup extends Component {
    render() {
        return (
            <div>
                <button
                    className="popup tile"
                    onMouseDown={this.props.click}
                >
                    <span className={this.props.className}></span>
                </button>
            </div>
        )
    }
}
