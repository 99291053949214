import React, { Component } from 'react'
import Piece from './piece'
import getInfo from './const'

function ASCII(c) {
    return c.charCodeAt(0)
}
function letter(n) {
    return String.fromCharCode(n + ASCII('A'))
}
function toNotation(x, y) {
    return letter(x) + (y + 1)
}

export default class Tile extends Component {
    toPiece(tile, id, visited, disabled) {
        const p = getInfo(tile)
        if (p.isEmpty) return
        const pieceClass = (p.isKing ? 'king ' : '') + (p.isWhite ? 'white ' : 'black ') + 'piece'
        return <Piece pieceClass={pieceClass} id={id} take={this.props.take} />
    }
    render() {
        const matrix = this.props.matrix
        const x = this.props.x
        const y = this.props.y
        const tile = matrix[x][y]
        const id = toNotation(x, y)
        const empty = tile === '_'

        const parity = (x + y) % 2
        const visited = this.props.lastMove.indexOf(id)
        const tileClass =
            (visited === -1 ? '' : 'highlight ') +
            (empty ? 'empty ' : '') +
            (parity ? 'even ' : 'odd ') +
            'tile'

        return (
            <td
                key={id}
                id={id}
                className={tileClass}
                onMouseDown={e => {
                    this.props.place(e, id)
                }}
            >
                {this.toPiece(tile, id, visited)}
            </td>
        )
    }
}
