import React, { Component } from 'react'

export default class Piece extends Component {
    render() {
        const pieceClass = this.props.pieceClass
        const id = this.props.id

        return (
            <div className={'tile'}>
                <button
                    className={pieceClass}
                    onMouseDown={e => {
                        this.props.take(e, id)
                    }}
                ></button>
            </div>
        )
    }
}
